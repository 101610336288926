<div class="caption">{{ 'other.share' | translate }}</div>
<div class="social-list">
  <ng-container *ngIf="type === 'def'">
    <div class="social-list__item"><a href="https://vk.com/share.php?url={{currentUrl}}" class="icon icon-vk"></a></div>
    <div class="social-list__item"><a href="https://connect.ok.ru/offer?url={{currentUrl}}" class="icon icon-ok"></a></div>
    <div class="social-list__item"><a href="https://api.whatsapp.com/send?text={{currentUrl}}" class="icon icon-whatsapp"></a></div>
  </ng-container>
  <ng-container *ngIf="type === 'img' && imageUrl">
    <div class="social-list__item"><a [href]="'https://vk.com/share.php?url=' + mainUrl + '&image=' + imageUrl" class="icon icon-vk"></a></div>
    <div class="social-list__item"><a [href]="'https://connect.ok.ru/offer?url=' + mainUrl + '&imageUrl=' + imageUrl" class="icon icon-ok"></a></div>
    <div class="social-list__item"><a [href]="'https://api.whatsapp.com/send?text=' + mainUrl + '%20' + imageUrl" class="icon icon-whatsapp"></a></div>
  </ng-container>
</div>

import {Injectable} from "@angular/core";
import {TokensAuth} from "../types/request/auth.request";
import {BehaviorSubject} from "rxjs";
import {ApiService} from "./api.service";
import {IProfileRequest} from "../types/request/profile.request";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  refreshToken: null | string = null;
  accessToken: null | string = null;
  dictationPage$  = new BehaviorSubject(false);
  profileInfo$ = new BehaviorSubject<IProfileRequest | null>(null);
  isAuthenticated$ = new BehaviorSubject(false);
  isStaff$ = new BehaviorSubject(false);
  constructor(private apiService: ApiService) {
  }

  get token(): string | null {
    if (this.accessToken) {
      return this.accessToken;
    }
    if (localStorage.getItem('access')) {
      this.accessToken = localStorage.getItem('access');
    }
    if (localStorage.getItem('refresh')) {
      this.refreshToken = localStorage.getItem('refresh');
    }
    return localStorage.getItem('access');
  }

  isAuthenticated(): boolean {
    this.isAuthenticated$.next(!!this.token);
    return !!this.token;
  }

  setToken(response: TokensAuth | null) {
    if (response) {
      this.refreshToken = response.refresh;
      this.accessToken = response.access;
      localStorage.setItem('refresh', this.refreshToken);
      localStorage.setItem('access', this.accessToken);
    } else {
      localStorage.clear();
      this.refreshToken = null;
      this.accessToken = null;
    }
  }

  profileInit() {
    this.apiService.getProfile().subscribe(profileInfo => {
      this.profileInfo$.next(profileInfo);
      this.isStaff$.next(profileInfo.is_staff);
    });

  }

  logout() {
    this.accessToken = null;
    this.refreshToken = null;
    this.profileInfo$.next(null);
    localStorage.setItem('refresh', '');
    localStorage.setItem('access', '');
    this.isAuthenticated();
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LocaleService } from '../service/locale.service';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private localeService: LocaleService) {}

  transform(value: any, format: string = 'mediumDate'): any {
    const locale = this.localeService.getLocale();
    const datePipe = new DatePipe(locale);
    return datePipe.transform(value, format);
  }
}

import { Injectable } from '@angular/core';
import {map, Observable, of} from "rxjs";
import { IEvent, IEventDetail, IEventResponse } from '../types/response/event.interface';
import { IFiles } from '../types/response/files.interface';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IMediaPartners,
  IMediaPhotos,
  IMediaProjects,
  IMediaResponse,
  IMediaVideo, IVideoMainPageAdvantages,
} from '../types/response/media.interface';
import { IFaq, IFaqResponse } from '../types/response/faq.interface';
import { IPageInfo } from '../types/response/page.interface';
import { IPerson, IPersonResponse } from '../types/response/person.interface';
import { IReview, IReviewResponse } from '../types/response/review.interface';
import {
  IChangePasswordPutRequest,
  IChangeProfilePatchRequest,
  IChangeProfilePutRequest,
  IProfileRequest
} from "../types/request/profile.request";
import {IRecoveryPassword, IRegisterRequest, TokensAuth} from "../types/request/auth.request";
import {IQuiz, IUserQuizGet} from "../types/response/dictant.interface";
import {
  IAdminUsers,
  IStatUser,
  IStatUserQuizz,
  IStatUserReg,
  IStatWinners
} from "../types/response/admin-auth.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  path = 'assets/img/projects/'


  constructor(private http: HttpClient) { }

  getEvents( limit = 12, offset = 0): Observable<IEventResponse> {
    return this.http.get<IEventResponse>(environment.apiUrl + 'blog/', {params: {offset, limit}}).pipe(map(events => {
      return events;
    }));
  }
  getEventsNext(url: string): Observable<IEventResponse> {
    return this.http.get<IEventResponse>(url).pipe(map(events => {
      return events;
    }));
  }
  getEventsPrev(url: string): Observable<IEventResponse> {
    return this.http.get<IEventResponse>(url).pipe(map(events => {
      return events;
    }));
  }





  getEventsMain(): Observable<IEvent[]> {
    return this.http.get<IEventResponse>(environment.apiUrl + 'blog/', { params: { limit: 3}}).pipe(map(events => {
      return events.results as IEvent[];
    }));
  }

  getPerson() {
    return this.http.get<IPersonResponse>(environment.apiUrl + 'main/persons/')
      .pipe(map(person => {
        return person.results as IPerson[];
    }));
  }

  getFaq(): Observable<IFaq[]> {
    return this.http.get<IFaqResponse>(environment.apiUrl + 'faq/').pipe(map(faq => {
      return faq.results as IFaq[];
    }));
  }

  getFaqMain(): Observable<IFaq[]> {
    return this.http.get<IFaqResponse>(environment.apiUrl + 'faq/main-page/').pipe(map(faq => {
      return faq.results as IFaq[];
    }));
  }

  getProjects() {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'main/projects/').pipe(map(res => {
      return res.results as IMediaProjects[];
    }));
  }

  getPartners(): Observable<IMediaPartners[]> {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'partners/').pipe(map(res => {
      return res.results as IMediaPartners[];
    }))
  }

  getFiles() {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'about/docs/').pipe(map(res => {
      return res.results as IFiles[];
    }))
  }

  getMediaPhotos(): Observable<IMediaPhotos[]> {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'media/photos/').pipe(map(res => {
      return res.results as IMediaPhotos[];
    }))
  }
  getMediaDocuments(): Observable<IFiles[]> {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'media/docs/').pipe(map(res => {
      return res.results as IFiles[];
    }))
  }
  getMediaVideos(): Observable<IMediaVideo[]> {
    return this.http.get<IMediaResponse>(environment.apiUrl + 'media/videos/').pipe(map(res => {
      return res.results as IMediaVideo[];
    }))
  }

  getPageSlug(slug: string): Observable<IPageInfo> {
    return this.http.get<IPageInfo>(environment.apiUrl + 'main/page/'+ slug +'/');
  }

  getEventsDetail(slug: string): Observable<IEventDetail> {
    return this.http.get<IEventDetail>(environment.apiUrl + 'blog/'+ slug +'/');
  }

  getMainVideoAdvantages() {
    return this.http.get<IVideoMainPageAdvantages[]>(environment.apiUrl + 'main/main-video/').pipe(map(videos => {
      if (videos.length) {
        return  videos;
      }
      return [];
    }));
  }

  getResponses(limit = 20, offset = 0) {
    return this.http.get<IReviewResponse>(environment.apiUrl + 'responses/', {params: {offset, limit}}).pipe(map(res => {
      return res;
    }))
  }


  getResponsesMore(url: string) {
    return this.http.get<IReviewResponse>(url).pipe(map(res => {
      return res;
    }))
  }

  getMainResponses() {
    return this.http.get<IReviewResponse>(environment.apiUrl + 'responses/main-page/').pipe(map(res => {
      return res.results as IReview[];
    }))
  }


  // authAPI

  authConfirm(data: { user_id: number; confirm_code: string; }) {
    return this.http.post<{refresh: string, access: string}>(environment.apiUrl + 'auth/confirm/', data);
  }

  authRefresh(data: {refresh: string}) {
    return this.http.post<{refresh: string; access: string;}>(environment.apiUrl + 'auth/refresh/', data);
  }

  authRegister(data: IRegisterRequest) {
    return this.http.post<any>(environment.apiUrl + 'auth/register/', data);
  }

  authLogin(data: any) {
    return this.http.post<TokensAuth>(environment.apiUrl + 'auth/token/', data);
  }


  sendReview(text: string) {
    return this.http.post<IProfileRequest>(`${environment.apiUrl}responses/create/`, {
      text
    });
  }

  // profile API


  getProfile() {
    return this.http.get<IProfileRequest>(environment.apiUrl + 'profile/');
  }

  patchUpdateProfile(data: any) {
    return this.http.patch<IProfileRequest>(`${environment.apiUrl}profile/update/`, data);
  }

  putUpdateProfile(data: any) {
    return this.http.put<IChangeProfilePutRequest>(`${environment.apiUrl}profile/update/`, data);
  }

  putChangePassword(id: number, data: IChangePasswordPutRequest) {
    return this.http.put(`${environment.apiUrl}profile/change_password/${id}/`, data);
  }


  postResendCode(user_id: number) {
    return this.http.post(`${environment.apiUrl}auth/resend-code/`, {user_id});
  }

  apiRecoveryPassword(email: string | null) {
    return this.http.post(`${environment.apiUrl}auth/password_reset/`, {email});
  }

  emailRecoveryCodeRepeat(email: string) {
   return this.http.post(`${environment.apiUrl}auth/resend-code/`, {email});
  }

  newChangePassword(data: IRecoveryPassword) {
    return this.http.post<any>(environment.apiUrl + 'auth/password_reset/confirm/', data);
  }


  // Quiz


  // Список квизов с вопросами, объект
  getQuiz() {
   return this.http.get<IQuiz[]>(`${environment.apiUrl}quiz/quizzes/`);
  }

  // Пользак если обновит тогда тут его прогресс
  getUserQuiz() {
   return this.http.get<IUserQuizGet[]>(`${environment.apiUrl}quiz/user-quizzes/`);
  }


  // когда начинаю квиз там кликаем сюда передаем id квиза
  postQuiz(quiz: number) {
   return this.http.post<{id: number; quiz: number}>(`${environment.apiUrl}quiz/user-quizzes/`, {quiz});
  }

  quizCalculate(quiz?: string) {
   return this.http.post(`${environment.apiUrl}quiz/user-quizzes/calculate_score/`, {});
  }

  startQuestion(questionId: number) {
   return this.http.post(`${environment.apiUrl}quiz/user-answers/start_question/`, {question: questionId});
  }

  submitAnswer(id: number, answers: any, answer_compare_json: any) {
   return this.http.post(`${environment.apiUrl}quiz/user-answers/submit_answer/`, {user_answer_id: id, answers, answer_compare_json});
  }


  checkStateUserQuiz() {
    return this.http.get<{status: 'start' | 'finish' | 'questions'}>(`${environment.apiUrl}quiz/user-quizzes/check_status/`);
  }


  // USERS profile
  getAllUsers(rowForm?: any) {
    return this.http.get<IAdminUsers>(`${environment.apiUrl}core/list-users`, {params: rowForm})
  }
  getAllUsersXls(rowForm?: any) {
    // @ts-ignore
    return this.http.get(`${environment.apiUrl}core/list-users-xls`, { observe: 'response', responseType: 'blob' })
      .pipe(map(response => {
        // Извлекаем заголовок content-disposition
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'users.xls'; // Имя по умолчанию

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match && match[1]) {
            filename = match[1];
          }
        }

        return { blob: response.body, filename };
      }))
  }

  getAllUsersUrls(url: string) {
    return this.http.get<IAdminUsers>(url)
  }



  getStatWinners(rowForm?: any) {
    return this.http.get<IStatWinners>(`${environment.apiUrl}core/charts/quiz-participation-winner/`, {params: rowForm})
  }


  getStatUserQuizz(rowForm?: any) {
    return this.http.get<IStatUserQuizz>(`${environment.apiUrl}core/charts/user-quizzes/`, {params: rowForm})
  }


  getStatUserReg(rowForm?: any) {
    return this.http.get<IStatUserReg>(`${environment.apiUrl}core/charts/user-registrations/`, {params: rowForm})
  }

  getStatUserStatus(rowForm?: any) {
    return this.http.get<IStatUser>(`${environment.apiUrl}core/charts/user-status/`, {params: rowForm})
  }

  //


  getTestPagintations(limit: number, offset: number) {
     return this.http.get<any>(environment.apiUrl + 'responses/', {params: {offset, limit}})
  }
}

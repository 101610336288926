import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LangService} from "../service/lang.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(private router: Router, private languageService: LangService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    const isEnglishRoute = url.startsWith('/en');
    const currentLang = this.languageService.getLanguage();

    if (isEnglishRoute && currentLang !== 'en') {
      // Устанавливаем английский язык, если мы на английском пути
      this.languageService.setLanguage('en');
    } else if (!isEnglishRoute && currentLang === 'en') {
      // Перенаправляем на английскую версию, если текущий язык — английский, а путь не включает /en
      const newUrl = '/en' + (url === '/' ? '' : url);
      this.router.navigate([newUrl], { replaceUrl: true });
      return false;
    } else if (!isEnglishRoute && currentLang !== 'ru') {
      // Устанавливаем русский язык, если мы на русском пути
      this.languageService.setLanguage('ru');
    }

    return true;
  }
}

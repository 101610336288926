import { Injectable } from '@angular/core';
import {BehaviorSubject, interval, map, of, Subject, switchMap, takeUntil, tap} from "rxjs";
import {Router} from "@angular/router";
import {ApiService} from "./api.service";
import {IDictQuestionPart, IQuiz, IUserQuizGet} from "../types/response/dictant.interface";
import {DomSanitizer} from "@angular/platform-browser";
@Injectable({
  providedIn: 'root'
})
export class DictantService {
  quiz!: IQuiz;
  questionCount$ = new BehaviorSubject(0);
  isLast$ = new BehaviorSubject(false);
  skipQuestion$ = new BehaviorSubject(false);
  maxQuestionIndex = 30;
  timeQuestion$ = new BehaviorSubject(15 * 120 * 1000);
  stop$ = new Subject();
  question$ = new BehaviorSubject<IDictQuestionPart | null>(null);
  initQuestion = 0;
  userAnswerID: number = 0;
  isFinishedDictant = false;
  isStartDictant = false;
  isQuestionDictant = false;
  isPreEndFormDictant = false;
  startTimeQuestion = 0;
  isLoadStateDict = false;
  dictantNotStart$ = new BehaviorSubject(true);
  dictantFinish$ = new BehaviorSubject(false);
  interval: any;
  constructor(private router: Router,
              private http: ApiService,
              private sanitizer: DomSanitizer) {
  }


  init() {
    return this.http.getUserQuiz().pipe(
      switchMap((req: IUserQuizGet[]) => {
        if (req.length && req[0].user_answers.length > 0) {
          // @ts-ignore
          const lastItemQuestion: any = req[0].user_answers[req[0].user_answers.length - 1];
          // последний ответ на вопрос пользователя уже отвечен?
          const isAnswer = !!lastItemQuestion.answered_at?.length;
          if (!isAnswer) {
            this.initQuestion = req[0].user_answers.length - 1;
          } else {
            this.initQuestion = req[0].user_answers.length;
          }
          // this.initQuestion = req[0].correct_answers_count + 1;
          this.questionCount$.next(this.initQuestion);
        }
        return this.getQuestionsQuiz();
      }),
      tap((quiz) => {
        quiz[0].questions = quiz[0].questions.map(item => {
          // @ts-ignore
          item.video_url = item.video_url ? this.sanitizer.bypassSecurityTrustResourceUrl(item.video_url) : '';
          return item;
        })
        this.quiz = quiz[0];
        this.maxQuestionIndex = this.quiz.questions.length - 1;
        this.dictantFinish$.next(false);
        this.question$.next(this.quiz.questions[this.initQuestion]);
      })
    )
  }
  saveQuestion() {

  }

  nextQuestion() {
    this.questionCount$.next(this.questionCount$.getValue() + 1);

    if (this.isLast$.getValue()) {
      this.editCheckStatusDictant({status: 'prefinish'});
      this.dictantFinish$.next(true);
      this.complete();
      return;
    }
    this.question$.next(this.quiz.questions[this.questionCount$.getValue()]);
    // @ts-ignore
    this.startQuestionPart((this.question$.getValue()?.id));

  }

  complete() {
    this.router.navigate(['dictation/end'])
  }





  initTimerStart(time: number) {
    if (!this.startTimeQuestion) {
      return;
    }
    /*this.stop$.next(true);
    this.stop$.complete();
    this.stop$ = new Subject();*/
    const endTime = new Date(new Date(this.startTimeQuestion).getTime() + time * 1000);
    time = endTime.getTime() - new Date().getTime();

    if (time < 0) {
      this.skipQuestion();
      return;
    }
    this.timeQuestion$.next(time);
    this.dictantNotStart$.next(false);
    clearInterval(this.interval);
    this.interval = setInterval(time => {
      const timeActual = this.timeQuestion$.getValue() - 1000;
      if (timeActual <= 0) {
        /*this.stop$.next(true);
        this.stop$.complete();*/
        this.skipQuestion();
        clearInterval(this.interval);
      }
      this.timeQuestion$.next(timeActual);
    }, 1000);
  }

  setCountQuestion(step: number) {
    this.questionCount$.next(step);
  }

  getProgress() {
    this.http.getUserQuiz().subscribe(req => {
      // console.log('getUserQuiz', req);
    });
  }

  getQuestionsQuiz() {
    return this.http.getQuiz().pipe(tap(req => {
      this.quiz = req[0];
    }))
  }

  startDictant() {
    return this.http.postQuiz(this.quiz.id).pipe(tap(x => {
      this.question$.next(this.quiz.questions[0]);
    }));
  }

  // вызываем перед началом следующего вопроса
  startQuestionPart(questionId: number) {
    this.http.startQuestion(questionId).subscribe((x: any) => {
      this.userAnswerID = x.id;
      this.startTimeQuestion = x.start_time;
      // @ts-ignore
      this.initTimerStart(this.question$.getValue()?.get_time_limit);
    });
  }


  checkStartDictant() {
    return this.http.checkStateUserQuiz().pipe(tap(x => {
      this.isStartDictant = x.status === 'start';
      this.isFinishedDictant = x.status === 'finish';
      this.isQuestionDictant = x.status === 'questions';
      this.isLoadStateDict = true;
    }))
  }

  editCheckStatusDictant(x: {status: 'start' | 'finish' | 'questions' | 'prefinish'}) {
    this.isStartDictant = x.status === 'start';
    this.isFinishedDictant = x.status === 'finish';
    this.isQuestionDictant = x.status === 'questions';
    this.isPreEndFormDictant = x.status === 'prefinish';
  }

  submitAnswer(id: number, answers: any, answer_compare_json: any) {
    return this.http.submitAnswer(id, answers, answer_compare_json);
  }

  private skipQuestion() {
    const question = this.question$.getValue() as IDictQuestionPart;
    this.submitAnswer(this.userAnswerID, [], null).subscribe(x => {
      this.skipQuestion$.next(true);
    });
  }

  reset() {
    this.questionCount$.next(0);
    this.skipQuestion$.next(false);
    this.maxQuestionIndex = 30;
    this.timeQuestion$.next(15 * 120 * 1000);
    this.stop$.next(true);
    this.stop$.complete();
    this.stop$ = new Subject();
    this.question$.next(null);
    this.initQuestion = 0;
    this.userAnswerID = 0;
    this.isFinishedDictant = false;
    this.isStartDictant = false;
    this.isQuestionDictant = false;
    this.isPreEndFormDictant = false;
    this.startTimeQuestion = 0;
    this.isLoadStateDict = false;
    this.dictantNotStart$.next(true);
    this.dictantFinish$.next(false);
  }


}

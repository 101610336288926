<div class="frame-page">
  <div class="frame-page__wrapper content-wrapper">
    <div class="frame-page__head">
      <div class="head-action"><ng-content select="[headaction]"></ng-content></div>
      <div class="frame-page-title">
        <h1 class="title">{{titlePage}}</h1>
        <ng-content select="[headtitle]"></ng-content>
      </div>
      <ng-content select="[headfooter]"></ng-content>
    </div>
    <div class="frame-page__content">
      <ng-content></ng-content>
    </div>
    <div class="frame-page__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>

<div class="field-input" [class.error]="isErrorCustom">
  <div *ngIf="label" class="label label-inner">{{label}}<span *ngIf="isRequired" class="required">*</span></div>
  <input
    [type]="type"
    class="input"
    [disabled]="disabled"
    [autocomplete]="false"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onChange($any($event.target).value)"
    (blur)="onTouched()"
    (focus)="onTouched()"
    (keyup)="writeValue($any($event.target).value)"
  >
</div>

<ng-container *ngIf="Mcontrol.errors && Mcontrol.touched">
  <div class="errors" *ngIf="Mcontrol.errors && Mcontrol.errors['required']">
    <div class="error">{{ 'errors.required' | translate }}</div>
  </div>
  <div class="errors" *ngIf="Mcontrol.errors && Mcontrol.errors['email']">
    <div class="error">{{ 'errors.email' | translate }}</div>
  </div>
</ng-container>
